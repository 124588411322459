import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';

const ApproachCardStyled = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-width: 100%;
  // min-height: 940px;
  aspect-ratio: 1;
  border-radius: 8px;
  color: var(--white);
  text-align: center;
  padding: 7rem 5rem;
  transition: 500ms ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 1023px) {
    padding: 5rem 2rem;
  }
  &.open {
    background-size: 4000%;
    background: var(--black);
    background-image: linear-gradient(
      to bottom,
      var(--black),
      var(--black)
    ) !important;
    background-position: center top;
  }
  .narrow {
    max-width: 400px;
    margin: 2rem auto 0;
  }
  .description {
    background: var(--greyLight);
    color: var(--textColor);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // padding: 4rem 3rem;
    transition: 200ms ease-in;
    overflow-y: auto;
    span {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      @media (max-width: 800px) {
        flex-direction: column;
      }
    }
    svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 3rem;
      z-index: 1;
      fill: var(--textColor);
    }
  }
  .description.reveal {
    opacity: 1;
    position: fixed;
    z-index: 1;
    width: 100%;
    div {
      padding: 3rem;
      margin: auto;
      p {
        max-width: 35em;
        margin-inline: auto;
      }
    }
    .description-image {
      max-height: 70vh;
    }
  }
  .cta {
    display: inline-block;
    cursor: pointer;
    color: var(--watermelon);
    padding: 2rem;
    line-height: 150%;
  }
`;

export default function ApproachCard(props) {
  // function toggleDescription(e) {
  //   e.currentTarget.parentElement.classList.toggle('open');
  //   e.currentTarget.previousSibling.classList.toggle('reveal');
  // }
  const { backgroundImage, title, subTitle, description, callToAction } = props;
  const [descState, toggleDescription] = useState(false);
  function toggleDesc() {
    toggleDescription(!descState);
  }
  return (
    <ApproachCardStyled
      style={{
        backgroundImage: `linear-gradient(to bottom, var(--black), rgba(0,0,0,0.3)),url(${
          backgroundImage ? backgroundImage : ''
        })`,
      }}
      onClick={toggleDesc}
    >
      <h4>{title}</h4>
      <p className="narrow">{subTitle ? subTitle : ''}</p>
      <div className={descState ? 'description reveal' : 'description'}>
        <VscClose />
        <span>
            <img
              src={backgroundImage}
              alt={title}
              class="description-image"
            />
          <div>
            <h4>{title}</h4>
            <p>{description ? description : ''}</p>
          </div>
        </span>
      </div>
      <span className="cta" role="button" tabIndex={0}>
        {callToAction ? callToAction : ''}
      </span>
    </ApproachCardStyled>
  );
}
