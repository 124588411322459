import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import approach from '../assets/images/approach.jpg';
import ApproachCard from '../components/ApproachCard';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const ApproachPageStyled = styled.div`
  width: 100%;
  .approach-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${approach});
    h3 {
      max-width: 800px;
      margin: 2rem auto;
    }
  }
  .approach-description {
    text-align: center;
    max-width: calc(var(--wrapper) * 0.75);
    margin: 5rem auto;
    .statement {
      max-width: calc(var(--wrapper) * 0.6);
      margin: 3rem auto;
      color: var(--borderColor);
      @media (max-width: 1023px) {
        font-size: 2.5rem;
      }
    }
    .stand-first {
      max-width: calc(var(--wrapper) * 0.5);
      margin: 3rem auto;
    }
  }
  .approach-container {
    max-width: var(--wrapper);
    width: 100%;
    margin: 4rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: inherit;
    justify-items: stretch;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      padding: 0rem 2rem;
    }
  }
  .approach-content {
    text-align: center;
    max-width: 700px;
    margin: 6rem auto;
  }
  .secondary-image {
    border-radius: calc(var(--borderRadius) * 2);
    box-shadow: var(--boxShadow);
  }
`;

const serializers = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, '');
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === 'blockquote' || style === 'quote') {
        return <blockquote>{props.children}</blockquote>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    code: (props) =>
      console.log('code block', props) || (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
  },
  list: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    )),
  listItem: (props) =>
    console.log('list', props) ||
    (props.type === 'bullet' ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    )),
  marks: {
    strong: (props) =>
      console.log('strong', props) || <strong>{props.children}</strong>,
    em: (props) => console.log('em', props) || <em>{props.children}</em>,
    code: (props) =>
      console.log('code', props) || <code>{props.children}</code>,
  },
};

export default function ApproachPage({ data }) {
  return (
    <>
      <SEO
        title={data.approach.nodes[0].title}
        image={data.approach.nodes[0].secondaryImage?.asset?.fluid?.src}
      />
      <ApproachPageStyled>
        <div className="wrapper">
          <section className="page-header approach-header">
            <div>
              <h2>{data.approach.nodes[0].title}</h2>
            </div>
          </section>
          <section className="approach-description">
            <h3 className="statement">{data.approach.nodes[0].statement}</h3>
            <h4 className="stand-first">
              {data.approach.nodes[0].subStatement}
            </h4>
            <div className="approach-content">
              <p>{data.approach.nodes[0].about}</p>
            </div>
            <Img
              fluid={data.approach.nodes[0].secondaryImage.asset.fluid}
              alt={data.approach.nodes[0].title}
              className="secondary-image"
              imgStyle={{ objectFit: 'contain' }}
            />
            <div className="approach-content">
              <h4>Behind the design</h4>
              <BlockContent
                blocks={data.approach.nodes[0]._rawContent}
                projectId="ji3xh6f0"
                dataset="production"
                serializers={serializers}
              />
            </div>
            <div className="approach-container">
              {data.approach.nodes[0].features.map((item, index) => (
                <ApproachCard
                  key={index}
                  title={item.title}
                  description={item.details}
                  callToAction="Learn more"
                  backgroundImage={item.image.asset.fluid.srcWebp}
                />
              ))}
            </div>
          </section>
        </div>
        <NavPages />
      </ApproachPageStyled>
    </>
  );
}

export const query = graphql`
  query ApproachQuery {
    approach: allSanityApproach {
      nodes {
        _rawContent
        mainImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        secondaryImage {
          asset {
            fluid(maxWidth: 1000, maxHeight: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        content {
          _key
          _type
          style
          list
          _rawChildren
        }
        features {
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
              title
            }
          }
          title
          details
        }
        about
        title
        statement
        subStatement
        id
      }
    }
  }
`;
